@tailwind base;

@tailwind components;

@tailwind utilities;

.select-search{
  border: 1px solid rgba(203, 213, 225);
  padding: 5px;
  border-radius: 5px;
}

.select-search__input{
  width: 100%;
}